import Header from './../components/header/Header'
import FM from "../img/FM.png";
import "./styles.css";




const Home = () => {
    return (
		<>
			<Header />
			<main className="section">
				<div className="container">
					<ul className="content-list">
						<li className="content-list__item">
							<h2 className="title-2">HELPING ATHLETES TO REACH NEW GOALS!
</h2>
							<p>
							We are a global official distributor of accessories
(apparel, rehabilitation equipment, medical apparel ( professionals medical masks, robes, gloves and others) as well as just general questions)
							</p>
						</li>
					<div class="intro__description">
          <p>
            Standing at a new launching point that carries hope and vitality,
            fahrenheit metabolics takes practical steps to grow in
            accordance with our strategy. We are striving to become a
            world-class company, competing at an international level. In the
            process of creating of a corporate culture
             fahrenheit metabolics adheres to the principle of
“People first”caring about the personal
            development of each person, and carrying out a variety of
            activities to encourage mutual growth of the individual and the
            group.
          </p>

        </div>
					</ul>
				</div>

				<div class="logo-panda">
          <a href="/">
          <img src= {FM} alt='logo'/>
          </a>
        </div>

			</main>
		</>
	);
}

export default Home;