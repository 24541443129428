import "./styles.css";

const Contacts = () => {
    return (
				<>
		   <div className="container">

      <div className="content">
        <div className="left-side">

        </div>
        <br></br>
        <div classNameName="right-side">
          <div className="topic-text">Send us a message </div>

          <div class="input__row">

            <div class="input-checkbox__row checkbox-1">
              <input type="checkbox" class="input__data-check" id="checkbox1" name="checkbox1"/>
              I understand that this website has no connection with external
              links presented here.
            </div>
            <div class="input-checkbox__row checkbox-2">
              <input type="checkbox" class="input__data-check" id="checkbox2" name="checkbox2"/>
              I Have read and agree to
               privacy policy
              of this website
            </div>
            <div class="input-checkbox__row checkwbox-3">
              <input type="checkbox" class="input__data-check" id="checkbox3" name="checkbox3"/>
              Processing of personal data and providing me with any
              information;
            </div>
            <div class="input-checkbox__row checkbox-4">
              <input type="checkbox" class="input__data-check" id="checkbox4" name="checkbox4"/>
              I certify that I am 21 or above years old;
            </div>
            <div class="input-checkbox__row checkbox-5">
              <input type="checkbox" class="input__data-check" id="checkbox5" name="checkbox5"/>
              I give my express consent to the disclosure of my personal data
              to third parties;
            </div>
            <div class="input-checkbox__row checkbox-6">
              <input type="checkbox" class="input__data-check" id="checkbox6" name="checkbox6"/>
              I Have read and agree to
             privacy policy
              of this website
            </div>
          </div>
          <br></br>
          <br></br>

          <form action="mailto:support@fahrenheitmetabolics.com" name="form" method="POST">
            <div className="input-box">
              <input
                type="text"
                placeholder="First name"
                name="name"
                id="name"

              />

            </div>
            <div className="input-box">
              <input
                type="text"
                placeholder="Email"
                name="email"
                id="email"

              />
              <label for="email">In format: your-name@email.com</label>
            </div>

            <div className="input-box message-box">
              <textarea placeholder="Message" name="message"></textarea>
            </div>
            <div className="button">
              <input type="submit" id="button" value="Send" src="mailto:support@fahrenheitmetabolics.com" />
            </div>
          </form>
        </div>

      </div>

    </div>

</>
	);
}

export default Contacts;