import './styles.css';


const Projects = () => {
	return (
	 <section className="privacy-policy-terms">
      <div className="container anti_doping">
        <h1 className="modal__h1"> ANTI-DOPING</h1>
        <br></br>
        <p className="modal__h1-subtitle">
"Doping, the road to nowhere
"        </p>
<br></br>
        <p className="modal__p">
          The principles of Bodybuilding, Fitness, CrossFit, and Sport are built upon the fusion of appropriate nutrition and steady weight training. While these sports aim to enhance physical strength, they are also inherently related to health as they constitute one of the most effective methods of anti-aging therapy.
        </p>
        <br></br>

        <p className="modal__p">
          By its principles, the Fahrenheit metabolics is strongly committed to the fight
          against drugs, because:
        </p>
        <ul className="modal__list">

          <li>Doping is against our principles of healthy lifestyle</li>
          <br></br>
          <li>Doping is against the ethics and laws in sport</li>
        </ul>
        <br></br>

        <p className="modal__p">
          Doping is not only a problem for sport; it is a problem for society
          in the XXI Century.
        </p>
        <br></br>
        <p className="modal__p -mod">
          The World Anti-Doping Code's anti-doping regulations are adhered to by the Fahrenheit metabolics as a signatory.
        </p>
        <ul className="modal__list">
          <li>
            Elaboration and following specific anti-doping control programs,
            since
          </li>
          <br></br>

          <li>
            Educating the athletes, coaches and National Federations through
            conferences, informative brochures, and specific courses.
          </li>
          <br></br>

          <li>
            Teaching and encouraging our National Federation members to
            incorporate active anti-doping programs at the national
          </li>

        </ul>
        <br></br>


        <br></br>
        <p className="modal__p -mod">
          The purpose is to provide information that can be amplified through
          the detailed and necessary study of the Fahrenheit metabolics  Anti- Doping Rules.
        </p>
        <br></br>
        <p className="modal__p -mod">
          We recommend the careful review of this content as a prior step in
          the study of the Fahrenheit metabolics  Anti-Doping Code document.
        </p>
        <div className="divider"></div>

        <br></br>

        <p className="modal__p">
          PRINCIPLES AND VALUES ASSOCIATED WITH CLEAN SPORT
        </p>
        <br></br>


        <p className="modal__p">
          Values are both the basis of who we are and the aspiration of who we
          want to be when we are at our best. They are the aspects and
          qualities that one holds to be most important. We all have values,
          but sometimes we disconnect from being that person.
        </p>
        <br></br>
        <p className="modal__p">
          Values are what we have, things we do, practice and live by.
          Additionally, values guide our behaviour and actions.
        </p>
        <br></br>
        <p className="modal__p">
          Values are part of the internal system that guides behaviour,
          whereas principles are external. Values are subjective, personal,
          emotional, and arguable, while principles are objective, factual,
          impersonal, and self-evident because they are indisputable.
        </p>
        <br></br>
        <p className="modal__p">
          Principles are universal truths based on natural laws. While values
          govern behaviours, principles govern the consequences of those
          behaviours. While values may vary from person to person, principles
          will always remain constant in the universe. In other words, even
          though values may differ, they are governed by the same principles.
        </p>
        <br></br>
        <p className="modal__p">
          The degree to which we recognize and live in harmony with principles
          determines if we’re moving toward living a principle-centred life or
          away from it. Because we know that principles always work, it only
          makes sense to have values that are based on principles. Over time,
          actions/behaviours become habits, which in turn become values.
        </p>
        <br></br>
        <p className="modal__p">
          Athletes and Athlete Support Personnel (ASP) should start by finding
          out their values which should not be an end in and of itself. They
          need to remember to always value the correct principles. If their
          values are not based on principles then they need to course-correct.
        </p>
        <br></br>
        <p className="modal__p">
          For instance, athletes should take some time to reflect on the
          values they can’t stand when they see them in others or themselves.
          The opposite of these values is a reflection of what the athlete
          stands for.
        </p>
        <br></br>
        <p className="modal__p">
          Athletes and ASP should have values that guide their decisions,
          things that are important and acceptable and those that are not. If
          they know what drives them, then they can understand others better.
          Although this may not be enough, the end goal is to have and
          practice values based on principles.
        </p>
        <br></br>
        <p className="modal__p">
          Anti-doping programs are founded on the intrinsic value of sport.
          This intrinsic value is often referred to as “the spirit of sport”:
          the ethical pursuit of human excellence through the dedicated
          perfection of each Athlete’s natural talents. The spirit of sport is
          the celebration of the human spirit, body and mind. It is the
          essence of Olympism and is reflected in the values we find in and
          through sport.
        </p>
        <br></br>


      </div>
    </section>
	);
};

export default Projects;
