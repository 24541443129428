import "./style.css";


import instagram from './../../img/icons/instagram.svg';
import twitter from './../../img/icons/twitter.svg';
import linkedIn from './../../img/icons/linkedIn.svg';


const Footer = () => {
    return (
		<footer className="footer">
			<div className="container">
				<div className="footer__wrapper">
					<ul className="social">

						<li className="social__item">
							<a href="https://www.instagram.com/">
								<img src={instagram} alt="instagram" />
							</a>
						</li>
						<li className="social__item">
							<a href="https://www.twitter.com">
								<img src={twitter} alt="twitter" />
							</a>
						</li>

						<li className="social__item">
							<a href="https://www.linkedin.com/">
								<img src={linkedIn} alt="linkedIn" />
							</a>
						</li>
					</ul>
					<div className="copyright">
             <p>Offers: support@fahrenheitmetabolics.com</p>
						<p>FAHRENHEIT METABOLICS© 2024</p>
					</div>
				</div>
			</div>
		</footer>
	);
}

export default Footer;