import React from "react";
import AB from './assets/images/about1.jpg';
import AB2 from './assets/images/about2.png';
import AB3 from './assets/images/about3.png';
import AB4 from './assets/images/about4.png';
import AB5 from './assets/images/about5.png';
import AB6 from './assets/images/about6.png';
import AB7 from './assets/images/about7.png';


function About() {
  return (
    <div className="About">
      <section id="section__product">
        <div class="section__product-title">
            <h1 class="title">ABOUT<br/><br/>FAHRENHEIT METABOLICS</h1>
  <p>We are known as a highly reliable and safe company in the market. Our products are manufactured in a professional environment that includes the latest sterilization technologies, sanitation standards, and strict quality control measures.</p>

    <img src={AB} alt="a" width={200} height={200}/>

        </div>
         <div class="section__product-title">
            <h1 class="title"><br/>MISSION</h1>
  <p>Fahrenheit metabolics - mission is to deliver cutting-edge and unique anabolic steroids, with the aim of saving and enhancing lives, meeting customer demands, and establishing themselves as a leading force in the industry.</p>

    <img src={AB2} alt="a"/>

        </div>
        <div class="section__product-title">
            <h1 class="title"><br/>QUALITY PRODUCTS</h1>
  <p>Quality is of utmost importance to us. We exclusively utilize Active Pharmaceutical Ingredients that are of exceptional quality, adhering to the standards set by the United States Pharmacopeia (USA) and British Pharmacopeia (BP). Furthermore, we have implemented rigorous processes to guarantee that all our products consistently meet the highest quality benchmarks in the industry.</p>
        </div>
        <div className="image__product">
        <img src={AB3} alt="a" width={200} height={200}/>
        <img src={AB4} alt="a" width={200} height={200}/>
        <img src={AB5} alt="a" width={200} height={200}/>
        <img src={AB6} alt="a" width={200} height={200}/>
        <img src={AB7} alt="a" width={200} height={200}/>
</div>
    </section>
    </div>
  );
}

export default About;
