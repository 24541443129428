import "./style.css";

const Header = () => {
    return (
		<header className="header">
			<div className="header__wrapper">
				<h1 className="header__title">
					<strong>
					WELCOME
     <br /> 
				WORLD SPORTS CLUB <br /> 
 <em>FAHRENHEIT METABOLICS</em>
					</strong>
					<br />IN SEARCH OF TALENTS!
				</h1>
				
				
			</div>
		</header>
	);
}

export default Header;