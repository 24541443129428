import { NavLink } from 'react-router-dom';
import BtnDarkMode from '../btnDarkMode/BtnDarkMode';
import GoogleTranslate from './GoogleTranslate';

import './style.css';



const Navbar = () => {
	const activeLink = 'nav-list__link nav-list__link--active';
	const normalLink = 'nav-list__link';


	return (
<>
		<nav className="nav">
			<div className="container">
				<div className="nav-row">
					<NavLink to="/" className="logo">
						<strong>FAHRENHEIT </strong> METABOLICS
					</NavLink>
					<BtnDarkMode />
<GoogleTranslate />
					<ul className="nav-list">
						<li className="nav-list__item">
							<NavLink
								to="/"
								className={({ isActive }) =>
									isActive ? activeLink : normalLink
								}
							>
								Welcome
							</NavLink>
						</li>
<li className="nav-list__item">
							<NavLink
								to="/about"
								className={({ isActive }) =>
									isActive ? activeLink : normalLink
								}
							>
								About
							</NavLink>
						</li>

						<li className="nav-list__item">
							<NavLink
								to="/projects"
								className={({ isActive }) =>
									isActive ? activeLink : normalLink
								}
							>
								Anti-Doping
							</NavLink>
						</li>
						<li className="nav-list__item">
							<NavLink
								to="/contacts"
								className={({ isActive }) =>
									isActive ? activeLink : normalLink
								}
							>
								Contact
							</NavLink>

						</li>


					</ul>
				</div>
			</div>
		</nav>

		</>

	);
};

export default Navbar;
