import { useEffect } from 'react';

  
const GoogleTranslate = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://cdn.gtranslate.net/widgets/latest/dwf.js';
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    window.gtranslateSettings = {
      default_language: 'en',
      languages: ['en', 'cs', 'ru'],
      wrapper_selector: '.gtranslate_wrapper',
      flag_size: 24,
      flag_style: '3d',
    };

    const gtranslateScript = document.createElement('script');
    gtranslateScript.src = 'https://cdn.gtranslate.net/widgets/latest/dwf.js';
    gtranslateScript.defer = true;
    document.body.appendChild(gtranslateScript);

    return () => {
      document.body.removeChild(script);
      document.body.removeChild(gtranslateScript);
    };
  }, []);

  return <div className="gtranslate_wrapper"></div>;
};
export default GoogleTranslate;